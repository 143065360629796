/** @jsx jsx */

import {
  useElements,
  useStripe,
  // CardNumberElement,
} from "@stripe/react-stripe-js";
import { FaSpinner } from "react-icons/fa";
import { StripeCardNumberElement } from "@stripe/stripe-js";
// import { navigate } from "gatsby";
import axios from "axios";
import React, { useState, FC } from "react";
import { Box, Button, Image, Flex, Text, jsx } from "theme-ui";
import CardInput from "./CardInput";

interface Props {
  payload: string;
}

interface Metadata {
  title: string;
  usd_amount: number | string;
  meals?: number;
  formatted_amount: string;
  goal_id?: string;
  user_id: string;
  thumbnail?: string | null;
}

export interface Payload {
  email: string;
  amount: string;
  currency: string;
  subscription: boolean;
  action: string | "transaction" | "charge" | null;
  metadata: { [key: string]: string } | Metadata;
}

const CardSetup: FC<Props> = ({ payload }) => {
  // fix build
  if (!payload) {
    return null;
  }

  const _payload: Payload = JSON.parse(
    Buffer.from(payload, "base64").toString("binary")
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null as any);
  const stripe = useStripe();
  const elements = useElements();

  // console.log({ _payload });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      return;
    }

    try {
      // get client secret
      const res = await axios.post(
        `${process.env.GATSBY_API_URL}/v1.1/stripe/setup`,
        {
          customerId: _payload.metadata.user_id,
        }
      );

      const client_secret: string = res.data["client_secret"];

      // setup card
      const result = await stripe.confirmCardSetup(client_secret, {
        payment_method: {
          card: elements.getElement("cardNumber") as StripeCardNumberElement,
          billing_details: {
            email: _payload.email,
          },
          metadata: {
            user_id: _payload.metadata.user_id,
          },
        },
      });

      if (result.error) {
        // setError(result.error?.message);
        throw new Error(result.error.message);
        // Display result.error.message in your UI.
      } else {
        // Notice:
        // -- The setup has succeeded. Display a success message and send
        // -- result.setupIntent.payment_method to your server to save the
        // -- card to a Customer
        // -- charge based on one-time or subscription along with payment_method and payload

        // make card payment
        const pay = await axios.post(
          `${process.env.GATSBY_API_URL}/v1.1/stripe/pay`,
          {
            payment_method: result.setupIntent?.payment_method,
            payload: _payload,
          }
        );

        const { status, client_secret } = pay.data;

        if (status === "succeeded") {
          // charged
          window.location.href = "/payment-complete?success=1";
        } else if (
          // action_required
          ["requires_payment_method", "requires_action"].includes(status)
        ) {
          console.log("re-authorization");

          await stripe
            .confirmCardPayment(client_secret, {
              payment_method: result.setupIntent?.payment_method as string,
              save_payment_method: true,
              setup_future_usage: "off_session",
            })
            .then((confirm) => {
              if (confirm.error) {
                // Show error to your customer
                throw new Error(confirm.error.message);
                // setError(confirm.error.message);
              } else {
                if (confirm.paymentIntent?.status === "succeeded") {
                  // The payment is complete!
                  // TODO: Redirect
                  window.location.href = "/payment-complete?success=1";
                }
              }
            });
        } else {
          // error with action_required
          // console.log("Re-authorization failed");
          throw new Error(status);
          // setError(status);
        }
      }
    } catch (error) {
      // console.log("General error");
      setError(error?.message);
    }

    setLoading(false);
  };

  return (
    <Box>
      <Box sx={{ textAlign: "center", mb: 2 }}>
        <Image
          src={require("../assets/images/stripe.svg")}
          sx={{ height: 60, border: "1px solid #ebebeb" }}
        ></Image>
        <Text variant="text.heading" sx={{ fontSize: 3, mb: 0 }}>
          Payment Information
        </Text>
        <Text variant="text.small">Pay with credt/debit card</Text>
      </Box>

      {error && (
        <Box variant="messages.error" mb={3}>
          <Text>{error}</Text>
        </Box>
      )}

      <CardInput />
      <Box mb={3}>
        <Button
          variant="buttons.primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <FaSpinner className="animate-spin" size={18} />
          ) : (
            "Donate"
          )}
        </Button>
      </Box>

      <Flex sx={{ alignItems: "center", justifyContent: "space-around" }}>
        <Image
          src={require("../assets/images/amex.svg")}
          alt=""
          variant="images.brand"
        />
        <Image
          src={require("../assets/images/credit-card.svg")}
          alt=""
          variant="images.brand"
        />
        <Image
          src={require("../assets/images/discover.svg")}
          alt=""
          variant="images.brand"
        />
        <Image
          src={require("../assets/images/mastercard.svg")}
          alt=""
          variant="images.brand"
        />
        <Image
          src={require("../assets/images/visa.svg")}
          alt=""
          variant="images.brand"
        />
      </Flex>

      {/* <Box pt={3}>
        <Text variant="text.copy">
          Your credit or debit card info is not stored on our servers but
          processed through PCI DSS complaint payment gateways
        </Text>
      </Box> */}
    </Box>
  );
};

export default CardSetup;
